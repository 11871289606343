import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Advantages from "./components/Advantages";
import BusinessCard from "./components/BusinessCard/BusinessCard";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Services from "./components/Services";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <Navbar isMainPage={true} />
            <Hero />
            <Advantages />
            <Services />
            <Contacts />
            <Footer />
          </>
        } />
        <Route path="/ceo" element={<BusinessCard />} />
      </Routes>
    </Router>
  );
}

export default App;
