import Navbar from '../Navbar'
import React from 'react'

const BusinessCard = () => {
    return (
        <>
            <Navbar isMainPage={false} />
            <section className="hero ceo section-center" id="about">
                <div className="ceo-banner">

                    <div className="avatar"/>
                    <h1>
                        <span>Nariman Mavliutov</span>
                    </h1>
                    <h3>CEO/Founder of NardApp</h3>
                    {/* <a href='https://www.linkedin.com/in/nariman-mavliutov/' 
                        target="_blank" 
                        className='nav-icon' rel="noreferrer">
                        <i className="icon linkedin"/>
                    </a> */}
                    <div className="qr">
                    </div>
                </div>
                <div className='diamond-bg'/>
                <div className='diamond-bg'/>
                <div className='diamond'/>
                <div className='diamond'/>
                <div className='diamond'/>
                <div className='diamond'/>
            </section>
        </>
    )
}

export default BusinessCard